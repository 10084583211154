const tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const players = [];
const playersLength = $('.video-wrapper').length;
function onYouTubeIframeAPIReady() {
  for (let i = 0; i < playersLength; i++) {
    players[i] = new YT.Player(`player${i}`, {
      playerVars: {
        'playsinline': 1
      },
      events: {
        'onStateChange': `onPlayer${i}StateChange`
      }
    });
  }
}

function reset() {
  $('.pause-audio').trigger('click');
  $('.visible').removeClass('visible');
  $('.closed').removeClass('closed');
  $('.can-stick').removeClass('can-stick');
}

function onPlayer0StateChange(event) {
  if (event.data == YT.PlayerState.PLAYING) {
    reset()
    $('#player0').closest('.video-wrapper').addClass('can-stick');
    if ($('#player1').length > 0) players[1].stopVideo();
  }
}
function onPlayer1StateChange(event) {
  if (event.data == YT.PlayerState.PLAYING) {
    reset()
    $('#player1').closest('.video-wrapper').addClass('can-stick');
    if ($('#player0').length > 0) players[0].stopVideo();
  }
}

function stopVideo() {
  for (let i = 0; i < playersLength; i++) {
    if ($(`#player${i}`).length > 0) players[i].stopVideo();
  }
}


$(function ($) {

  // On scroll
  setTimeout(function () {
    onScroll();
    $(document).on('scroll', function () {
      onScroll();
    });
  }, 10);
  let endPosition = 0;

  if ($('.article-header').length > 0) {
    endPosition = $('.article-header').offset().top + $('.article-header').innerHeight() + $('.article-content').innerHeight();
  }

  $('.hint-toggle').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.hint-wrapper').toggleClass('is-open');
  });

  $('.close-fixed').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.visible').removeClass('visible');
    $(this).closest('.fixed').removeClass('fixed').addClass('closed');
    $('.pause-audio').trigger('click');
    stopVideo();
  });

  $('.play-audio').on('click', function(e) {
    e.preventDefault();
    $('.visible').removeClass('visible');
    $('.pause-audio').trigger('click');
    $('.can-stick').removeClass('can-stick fixed');
    let wrapper = $(this).closest('.audio-block-wrapper');
    if ($(this).hasClass('btn-live-audio')) {
      $('.btn-live-audio').hide();
      $('.btn-live-audio').siblings('.pause-audio').css('display', 'flex');
      wrapper = $('.live-audio');
      if (!$('.live-audio audio').attr('src')) $('.live-audio audio').attr('src', $('.live-audio audio').attr('data-src'));
    }

    if ($(this).closest('.today-section').length > 0) {
      wrapper = $(this).closest('.today-section');
    }
    wrapper.find('audio').trigger('play');
    wrapper.find('.play-audio').hide();
    wrapper.find('.pause-audio').css('display', 'flex');
    wrapper.find('.sticky-audio').addClass('visible');
    stopVideo();
  });

  $('.pause-audio').on('click', function(e) {
    e.preventDefault();
    let wrapper = $(this).closest('.audio-block-wrapper');
    if ($(this).siblings('.play-audio').hasClass('btn-live-audio')) {
      $('.btn-live-audio').siblings('.pause-audio').hide();
      $('.btn-live-audio').css('display', 'flex');
      wrapper = $('.live-audio');
    }

    if ($(this).closest('.today-section').length > 0) {
      wrapper = $(this).closest('.today-section');
    }
    wrapper.find('audio').trigger('pause');
    wrapper.find('.pause-audio').hide();
    wrapper.find('.play-audio').css('display', 'flex');
  });

  const audioFiles = [...document.getElementsByTagName('audio')];

  if (audioFiles.length > 0) {
    audioFiles.forEach((audio, index) => {
      let audioEl = $('audio').eq(index).closest('.audio-block-wrapper');
      audio.addEventListener('loadedmetadata', function(_event) {
        const totalTime = audio.duration;
        const minutes = "0" + Math.floor(totalTime / 60);
        const seconds = "0" + Math.floor(totalTime % 60);
        const totalText = minutes.substr(-2) + ":" + seconds.substr(-2);
        audioEl.find('.total-time').text(totalText);
      });

      audio.addEventListener('timeupdate', function () {
        const currentTime = audio.currentTime || 0;
        const duration = audio.duration || 0;
        const progress = (currentTime / duration * 100).toFixed(1);

        const minutes = "0" + Math.floor(currentTime / 60);
        const seconds = "0" + Math.floor(currentTime % 60);
        const currentText = minutes.substr(-2) + ":" + seconds.substr(-2);
        audioEl.find('.current-time').text(currentText);
        audioEl.find('.audio-progress').css('width', progress + '%')
      }, false);

      audioEl.find('.audio-bar').on('click', function(e){
        const barWidth = $(this).width();
        const duration = audio.duration || 0;
        const cursorPosition = e.pageX - $(this).offset().left;
        const progress = (cursorPosition / barWidth * 100).toFixed(1);
        audioEl.find('.audio-progress').css('width', progress + '%');
        audio.currentTime = cursorPosition / barWidth * duration;
      });

    });
  }


  function onScroll() {
    let position = $(window).scrollTop();

    // Fixed share buttons
    if ($('.article-socials').length > 0) {
      if (position + $(window).height() > endPosition) {
        $('.article-socials').addClass('hidden-mobile');
      } else {
        $('.article-socials').removeClass('hidden-mobile');
      }
    }
  }

});
