$(function($){

  // Add class to body 
  $('body').addClass('doc-ready')

  // Hidden menu
  $('.hamburger-btn, .close-menu').on('click', function(e) {
    e.preventDefault();

    $('body').toggleClass('menu-overflow-hidden');
    $('.global-overlay').fadeToggle();
    $('.hidden-menu-wrapper').toggleClass('is-open');
  });

  $('.global-overlay').on('click', function(e) {
    e.preventDefault();

    $('body').removeClass('menu-overflow-hidden');
    $('.global-overlay').fadeOut();
    $('.modal-wrapper').fadeOut();
    $('.hidden-menu-wrapper').removeClass('is-open');
  });

  // Menu search
  $('.search, .close-search').on('click', function(e) {
    e.preventDefault();

    $('.search-form').fadeToggle();
  });


  $('.hidden-menu .has-dd').on('click', function (e) {
    e.preventDefault();
    $(this).siblings('.has-dd').removeClass('is-open');
    $(this).toggleClass('is-open');
  });

  $('.livescore-tabs button').on('click', function(e) {
    const tab = $(this).data('tab');
    $(this).addClass('active').siblings().removeClass('active');

    $(`.livescore-content[data-tab="${tab}"]`).addClass('active').siblings('.livescore-content').removeClass('active');
  });

  $('.horizontal-scroll-wrapper .horizontal-scroll').on('scroll', function () {
    var $width = $(this).outerWidth()
    var $scrollWidth = $(this)[0].scrollWidth;
    var $scrollLeft = $(this).scrollLeft();

    if ($scrollWidth - $width === $scrollLeft) {
      $(this).closest('.horizontal-scroll-wrapper').addClass('is-right').removeClass('is-left');
    } else if ($scrollLeft === 0) {
      $(this).closest('.horizontal-scroll-wrapper').addClass('is-left').removeClass('is-right');
    } else {
      $(this).closest('.horizontal-scroll-wrapper').removeClass('is-left is-right');
    }
  });


  if ($(window).width() >= 992) {
    let sb1;
    let sb2;
    if ($('.scroll-1 .scroll-viewport').length > 0) {
      sb1 = new ScrollBooster({
        viewport: document.querySelector('.scroll-1 .scroll-viewport'),
        content: document.querySelector('.scroll-1 .scroll-content'),
        scrollMode: 'transform',
        direction: 'horizontal',
        textSelection: true,
      });
    }
    if ($('.scroll-2 .scroll-viewport').length > 0) {
      sb2 = new ScrollBooster({
        viewport: document.querySelector('.scroll-2 .scroll-viewport'),
        content: document.querySelector('.scroll-2 .scroll-content'),
        scrollMode: 'transform',
        direction: 'horizontal',
        textSelection: true,
      });
    }

    const moveBy = 400;
    let initialOffset;

    if ($('.scroll-content').length > 0) {
      initialOffset = $('.scroll-content').offset().left;
    }

    $('.arrow-next').on('click', function() {
      const scrollContent = $(this).closest('.scroll-wrapper');
      const currentOffset = Math.abs(parseInt(scrollContent.find('.scroll-content').offset().left - initialOffset));
      const viewportWidth = scrollContent.find('.scroll-viewport').width();
      const contentWidth = scrollContent.find('.scroll-content').width();
      const newX = Math.min(contentWidth - viewportWidth, currentOffset + moveBy);
      
      if (scrollContent.hasClass('scroll-1')) {
        sb1.scrollTo({ x: newX, y: 0 });
      }
      if (scrollContent.hasClass('scroll-2')) {
        sb2.scrollTo({ x: newX, y: 0 });
      }

      if (newX === contentWidth - viewportWidth) {
        $(this).hide();
      } else {
        $(this).show();
      }

      if (currentOffset + moveBy > 0) {
        $(this).siblings('.arrow-prev').show();
      }

    });

    $('.arrow-prev').on('click', function() {
      const scrollContent = $(this).closest('.scroll-wrapper');
      const currentOffset = Math.abs(parseInt(scrollContent.find('.scroll-content').offset().left - initialOffset));
      const viewportWidth = scrollContent.find('.scroll-viewport').width();
      const contentWidth = scrollContent.find('.scroll-content').width();
      const newX = Math.max(0, currentOffset - moveBy);
      if (scrollContent.hasClass('scroll-1')) {
        sb1.scrollTo({ x: newX, y: 0 });
      }
      if (scrollContent.hasClass('scroll-2')) {
        sb2.scrollTo({ x: newX, y: 0 });
      }
      if (newX === 0) {
        $(this).hide();
      } else {
        $(this).show();
      }

      if (newX < contentWidth - viewportWidth) {
        $(this).siblings('.arrow-next').show();
      }

    });

  }

});

